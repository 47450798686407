$logoFamily: "Montserrat";
$titleFmaily: "Montserrat";
$subTitleFmaily: "Montserrat";
$txtFamily: "Montserrat";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $primary;
  text-transform: uppercase;
}

h1 {
  font-family: $titleFmaily;
}

h2 {
  font-family: $subTitleFmaily;
  font-size: 24px;
  letter-spacing: 10px;
  font-weight: 800;
}

h3 {
  font-family: $subTitleFmaily;
  font-size: 16px;
  letter-spacing: 10px;
  font-weight: 800;
}

h4 {
  font-family: $subTitleFmaily;
  letter-spacing: 3px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0.5px;
  color: white;
  font-weight: 700;
  font-size: 14px;
}

.form-text {
  color: grey;
}

@media only screen and (max-width: 640px) {
}
