$primary: #fcd503;
$secondary: #0a0b0a;

$white: #fff;
$black: #000;
$darkgray: #999;
$lightgray: #ccc;

$txt: #000;
$txtRevert: #fff;
$altTxt: #f8f6f0;
